<template>
  <div>
    <el-card>
      <div v-loading = "titleloading">
        <div class="top-tit">{{message.Remark}}</div>
        <div class = "vicefont" style="margin-top:20px">创建时间：{{message.AddTime}}</div>
        <div class = "vicefont">
          <span>适用员工：{{message.EmployeeCount}} 个 </span> 
          <el-button type="text" style="margin:0px 10px" @click="$router.push({path:'/qyCustomer/callmeedit?Id='+$route.query.Id})">查看详情</el-button>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <div class = "flexRow">
        <div class = "flexRow">
          <div class = "keyFont">统计时间：</div>
          <el-date-picker
            v-model="timevalue"
            type="daterange"
            :clearable="false"
            align="left"
            unlink-panels
            @change="changetime"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <div class = "flexRow" style="margin:0px 15px">
          <div class = "keyFont">投放入口：</div>
          <el-select v-model="dataentrance" placeholder="请选择投放入口" @change="changedataentrance">
            <el-option v-for="(v,i) in entrancelist" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="querytop">查询</el-button>
       <!-- <el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccns132cLXPQ2GlmFIyMYVjNe"  target="_blank"
         style="margin-left:15px">统计指标说明</el-link> -->
      </div>
      <div style="margin-top:20px">
        <div class = "flexRow bluebox" v-loading = "blueloading">
          <div class = "bluebox-msgbox">
            <div class = "bluebox-msgbox-top">{{message.AllClickMemberCount}}</div>
            <div class = "bluebox-msgbox-bottom" style="margin-top:10px">点击人数</div>
          </div>
          <div class = "line"></div>
          <div class = "bluebox-msgbox">
            <div class = "bluebox-msgbox-top">{{message.AllAddCount}}</div>
            <div class = "bluebox-msgbox-bottom" style="margin-top:10px">新增好友关系</div>
          </div>
          <div class = "line"></div>
          <div class = "bluebox-msgbox">
            <div class = "bluebox-msgbox-top">{{message.AllLoseCount}}</div>
            <div class = "bluebox-msgbox-bottom" style="margin-top:10px">流失好友关系</div>
          </div>
          <div class = "line"></div>
          <div class = "bluebox-msgbox">
            <div class = "bluebox-msgbox-top">{{message.AllActualAddCount}}</div>
            <div class = "bluebox-msgbox-bottom" style="margin-top:10px">净增好友关系</div>
          </div>
        </div>
        <el-radio-group v-model="relation" style="margin-top:20px;" @change="changerelation">
          <el-radio-button :label="0">新增好友关系</el-radio-button>
          <el-radio-button :label="1">流失好友关系</el-radio-button>
          <el-radio-button :label="2">净增好友关系</el-radio-button>
        </el-radio-group>
        <div style="width:100%;height:300px">
          <line-chart v-if="chartdata&&chartdata.length" :info="chartdata" v-loading="chartloading" :tooltip='tooltip'
           :id="'Chart'" style="width:100%;height:300px"></line-chart>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="流量数据" name="1"></el-tab-pane>
        <el-tab-pane label="转化数据" name="2"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName==1">
        <div class = "flexRow" style="flex-wrap:wrap">
          <div class = "flexRow" style="margin:0px 0px 10px 15px">
            <div class = "keyFont">统计时间：</div>
            <el-date-picker
              v-model="tabletime2"
              type="daterange"
              :clearable="false"
              align="left"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class = "flexRow" style="margin:0px 0px 10px 15px">
            <div class = "keyFont">投放入口：</div>
            <el-select v-model="tableentrance2" placeholder="请选择投放入口" >
              <el-option v-for="(v,i) in entrancelist" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <el-button type="primary" style="margin:0px 0px 10px 15px" @click="query2">查询</el-button>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tablelist2" v-loading="tableloading2" class = "table" :header-row-style="{'height':'48px'}">
            <el-table-column prop="" label="客户" width="200px" key="1">
              <template slot-scope="scope">
                <div class = "flexRow">
                  <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaulrheader" style="width:40px;height:40px;border-radius:3px" alt="">
                  <div style="width:150px;margin-left:5px">
                    <div class = "fontHidden" style="width:100%">{{scope.row.WxNickname}}</div>
                    <div>{{scope.row.Phone}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="AddTime" label="点击时间" key="2"></el-table-column>
            <el-table-column prop="PlacementEntranceTypeValue" label="入口"  key="3">
              <!-- <template slot-scope="scope">
                <div v-if="scope.row.PlacementEntranceType==1" class = "flexRow">
                  <img :src="scope.row.ProductImgUrlComplete" style="width:40px;height:40px;border-radius:3px" alt="">
                  <div style="width:300px;margin-left:5px;overflow:hidden">
                    <div class = "fontHidden" style="width:100%">{{scope.row.ProductName}}</div>
                    <div class = "fontHidden" style="width:100%;color:#606266">{{scope.row.ProductNo}}</div>
                  </div>
                </div>
                <div v-else class = "flexRow">
                  {{scope.row.PlacementEntranceTypeValue}}
                </div>
              </template> -->
            </el-table-column>
            <el-table-column prop="" label="操作" key="4">
              <template slot-scope="scope">
                <el-button type="text" @click="todetail(scope.row)">客户详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:10px;text-align:right" v-if="total2">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total2">
          </el-pagination>
        </div>
      </div>
      <div v-if="activeName==2">
        <div class = "flexRow" style="flex-wrap:wrap">
          <div class = "flexRow" style="margin:0px 0px 10px 15px">
            <div class = "keyFont">添加时间：</div>
            <el-date-picker
              v-model="tabletime"
              type="daterange"
              :clearable="false"
              align="left"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="tablepickerOptions">
            </el-date-picker>
          </div>
          <div class = "flexRow" style="margin:0px 0px 10px 15px">
            <div class = "keyFont">投放入口：</div>
            <el-select v-model="tableentrance" placeholder="请选择投放入口" >
              <el-option v-for="(v,i) in entrancelist" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class = "flexRow" style="margin:0px 0px 10px 15px">
            <div class = "keyFont">流失情况：</div>
            <el-select v-model="tableloss" placeholder="客户是否流失"  clearable>
              <el-option v-for="(v,i) in tablelosslist" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <el-button type="primary" style="margin:0px 0px 10px 15px" @click="query">查询</el-button>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tablelist" v-loading = "tableloading" class = "table" :header-row-style="{'height':'48px'}">
            <el-table-column prop="" label="客户" width="200px" key="5">
              <template slot-scope="scope">
                <div class = "flexRow">
                  <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaulrheader" style="width:40px;height:40px;border-radius:3px" alt="">
                  <div style="width:150px;margin-left:5px">
                    <div class = "fontHidden" style="width:100%">{{scope.row.WxNickname}}</div>
                    <div>{{scope.row.Phone}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="添加员工" key="6">
              <template slot-scope="scope">
                <el-tag type="info" plain>
                  <i class = "el-icon-user"></i> <span style="margin-left:5px">{{scope.row.EmployeeName}}</span> 
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="AddTime" label="添加时间" key="7"></el-table-column>
            <el-table-column prop="PlacementEntranceTypeValue" label="入口" width="350px" key="8">
              <template slot-scope="scope">
                <div v-if="scope.row.PlacementEntranceType==1" class = "flexRow">
                  <img :src="scope.row.ProductImgUrlComplete" style="width:40px;height:40px;border-radius:3px" alt="">
                  <div style="width:300px;margin-left:5px;overflow:hidden">
                    <div class = "fontHidden" style="width:100%">{{scope.row.ProductName}}</div>
                    <div class = "fontHidden" style="width:100%;color:#606266">{{scope.row.ProductNo}}</div>
                  </div>
                </div>
                <div v-else class = "flexRow">
                  {{scope.row.PlacementEntranceTypeValue}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="是否流失" key="9">
              <template slot-scope="scope">
                <div>{{scope.row.IsLose?'是':'否'}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="DeleteTime" label="流失时间" key="10">
              <template slot-scope="scope">
                <div> {{scope.row.IsLose?scope.row.DeleteTime:'— —'}} </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" key="11">
              <template slot-scope="scope">
                <el-button type="text" @click="todetail(scope.row)">客户详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:10px;text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import lineChart from '@/components/Charts/qyWechatChart2'
import config from "@/config"
import {
  qyWeixinContactMestatisOverView,
  qyWeixinContactMedataList,
  qyWeixinContactMeflowList
}from "@/api/sv1.0.0"
export default {
  components: {
      lineChart
  },
  data () {
    return {
      defaulrheader:config.DEFAULT_HEADER,
      goUrls:config.GO_URL,
      titleloading:false,
      blueloading:false,
      timevalue:'',
      minDate:'',
      pickerOptions:{},
      dataentrance:1,
      entrancelist:[
        {value:null,label:'全部入口'},
        {value:1,label:'商品详情页'},
        {value:2,label:'支付成功页'},
        {value:3,label:'商城首页'},
        {value:4,label:'商城自定义页面'},
      ],
      relation:0,
      tooltip:'新增好友关系',
      chartloading:false,
      chartdata:[],
      chartdata2:[],
      message:{},

      tabletime:'',
      tableminDate:'',
      tablepickerOptions:{},
      tableentrance:1,
      tableloss:null,
      tablelosslist:[
        {value:true,label:'是'},
        {value:false,label:'否'},
      ],
      activeName:'1',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      sizepage:20,
      total:null,

      tabletime2:'',
      tableentrance2:1,
      tablelist2:[],
      tableloading2:false,
      currentPage2:1,
      sizepage2:20,
      total2:null,
    }
  },
  created () {
    if(this.$route.query.type){
      this.dataentrance = Number(this.$route.query.type)
      this.tableentrance2 = Number(this.$route.query.type)
    }
    this.getdefaulttime()
    this.gettopmsg()
    this.gettablelist2()
  },
  methods: {
    querytop(){
      // console.log((this.timevalue[1].getTime()-this.timevalue[0].getTime())/1000/60/60/24)
      if((this.timevalue[1].getTime()-this.timevalue[0].getTime())/1000/60/60/24>31){
        this.$message.error("最多可查询31天的数据")
        return false
      }
      this.gettopmsg()
    },
    getdefaulttime(){
      let date = new Date()
      let starttime = new Date(date.getTime()-1000*60*60*24*30)
      let endtime = new Date()
      this.timevalue = [starttime,endtime]
      this.tabletime = [starttime,endtime]
      this.tabletime2 = [starttime,endtime]
    },
    handleClick(e){
      this.activeName = e.name
      this.getdefaulttime()
      this.tableentrance2 = 1
      this.tableentrance = 1
      this.tableloss = null
      this.currentPage = 1
      this.currentPage2=1
      this.sizepage = 20
      this.sizepage2 = 20
      if(e.name==1){
        this.gettablelist2()
      }else{
        this.gettablelist()
      }
      // console.log(e.name)
    },
    query2(){
      if((this.tabletime2[1].getTime()-this.tabletime2[0].getTime())/1000/60/60/24>31){
        this.$message.error("最多可查询31天的数据")
        return false
      }
      this.currentPage2 = 1
      this.gettablelist2()
    },
    async gettablelist2(){
      this.tableloading2 = true
      try{
        let data = {
          QyWeixinContactMeId:this.$route.query.Id,
          StartTime:this.amendtime(this.tabletime[0],false),
          EndTime:this.amendtime(this.tabletime[1],true),
          PlacementEntranceType:this.tableentrance2,
          // IsLose:this.tableloss,
          Skip:(this.currentPage2-1)*this.sizepage2,
          Take:this.sizepage2,
        }
        // console.log(data)
        let result = await qyWeixinContactMeflowList(data)
        if(result.IsSuccess){
          this.tablelist2 = result.Result.Results
          this.total2 = result.Result.Total
        }
      }finally{
        this.tableloading2 = false
      }
    },
    handleSizeChange2(e){
      this.currentPage2 = 1
      this.sizepage2 = e
      this.gettablelist2()
    },
    handleCurrentChange2(e){
      this.currentPage2 = e
      this.gettablelist2()
    },
    changetime(e){
      // this.gettopmsg()
    },
    changedataentrance(e){
      // this.gettopmsg()
    },
    changerelation(e){
      let data = []
      if(e==0){
        this.tooltip = '新增好友关系'
        data = this.changekey(this.message.AddCountList)
      }else if(e==1){
        this.tooltip = '流失好友关系'
        data = this.changekey(this.message.LoseCountList)
      }else if(e==2){
        this.tooltip = '净增好友关系'
        data = this.changekey(this.message.ActualAddCountList)
      }
      this.chartdata = [this.changekey(this.message.ClickMemberCountList),data]
      // console.log(this.chartdata,111)
    },
    changekey(arr){
      if(arr&&arr.length){
        return arr.map((v)=>{
          v.value = v.Count
          v.label = v.CurrentDate.substring(5,v.CurrentDate.indexOf(' '))
          return v
        })
      }else{
        return []
      }
    },
    amendtime(time,isendtime){
      if(time){
        if(isendtime){
          time = new Date(time.getTime()+1000*60*60*24)
        }
        let year = time.getFullYear()
        let month = time.getMonth()+1>=10?time.getMonth()+1:'0'+(time.getMonth()+1)
        let day = time.getDate()>=10?time.getDate():'0'+time.getDate()
        return year+'-'+month+'-'+day
      }
    },
    async gettopmsg(){
      this.titleloading = true
      this.blueloading = true
      this.chartloading = true
      try{
        let data = {
          QyWeixinContactMeId:this.$route.query.Id,
          StartTime:this.amendtime(this.timevalue[0],false),
          EndTime:this.amendtime(this.timevalue[1],true),
          PlacementEntranceType:this.dataentrance,
          PageType:this.$route.query.type?this.$route.query.type:'',
          noError:true
        }
        // console.log(data)
        let result = await qyWeixinContactMestatisOverView(data)
        if(result.IsSuccess){
          // console.log(result.Result)
          this.message = result.Result
          // this.chartdata2 = this.changekey(result.Result.ClickMemberCountList)
          this.changerelation(this.relation)
        }else{
          // console.log(result)
          this.$message.error(result.Message)
          if(result.ErrorCode==1000000 && this.$route.query.type){
            // this.$router.push({
            //   path:'/qyCustomer/risefans'
            // })
            this.$router.go(-1)
          }
        }
      }finally{
        this.titleloading = false
        this.blueloading = false
        this.chartloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    query(){
      // tabletime
      if((this.tabletime[1].getTime()-this.tabletime[0].getTime())/1000/60/60/24>31){
        this.$message.error("最多可查询31天的数据")
        return false
      }
      this.currentPage = 1
      this.gettablelist()
    },
    async gettablelist(){
      this.tableloading = true
      try{
        let data = {
          QyWeixinContactMeId:this.$route.query.Id,
          StartTime:this.amendtime(this.tabletime[0],false),
          EndTime:this.amendtime(this.tabletime[1],true),
          PlacementEntranceType:this.tableentrance,
          IsLose:this.tableloss,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage,
        }
        // console.log(data)
        let result = await qyWeixinContactMedataList(data)
        if(result.IsSuccess){
          this.tablelist = result.Result.Results
          this.total = result.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    todetail(e){
      let headsUrls = process.env.NODE_ENV == "production" ? "index.html#" : "";
			let url = this.goUrls + headsUrls + "/Customer/CustomerDetail?Id=" + e.MemberId;
			window.open(url);
    },
  }
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .top-tit{
    font-size: 16px;
    font-weight: bold;
  }
  .vicefont{
    font-size: 14px;
    color: #606266;
    line-height: 1.3;
  }
  .keyFont{
    font-size: 14px;
    font-weight: bold;
    word-break: normal;
    color: #606266;
  }
  .fontHidden{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  .bluebox{
    padding:10px;
    border:1px solid #409EFF;
    background: #FBFDFF;
    justify-content: space-between;
    .line{
      height: 40px;
      width: 1px;
      background: rgba(153, 153, 153,.5);
      /* border:1px solid black */
    }
    .bluebox-msgbox{
      padding: 10px 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .bluebox-msgbox-top{
        font-size: 18px;
        word-break: normal;
        color: #303133;
        font-weight: bold;
      }
      .bluebox-msgbox-bottom{
        font-size: 14px;
        color: #b4b4b4;
        white-space: nowrap;
      }
    }
  }
</style>
<style>

  .table .el-table__body td,.el-table__body th{
    padding:0px !important;
    height: 60px !important;
  }
</style>