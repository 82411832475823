<template>
	<div :id="id" :class="className" :style="{ height: height, width: width }" :info="info" :tooltip="tooltip"></div>
</template>

<script>
	import echarts from "echarts";
	import resize from "./mixins/resize";
	
	export default {
		mixins: [resize],
		props: {
			width: {
				type: String,
				default: "100%",
			},
			height: {
				type: String,
				default: "100%",
			},
			tooltip: {
				type: String,
				default: "人数",
			},
			className: {
				type: String,
				default: "chart",
			},
			id: {
				type: String,
				default: "chart",
			},
			info: {
				type: Array,
				default: () => {
					return [];
				},
			},
		},
		watch: {
			info(val) {
				this.datainfo = val;
				this.initChart();
			},
			tooltip(val) {
				this.initChart();
			},
		},
		mounted() {
			// console.log(this.tooltip)
			this.$nextTick(()=>{
				this.myChart = echarts.init(document.getElementById(this.id));
			})

			this.datainfo = this.info;
			this.initChart();
			
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		data() {
			return {
				chart: null,
				datainfo: [],
				myChart: null,
			};
		},
		methods: {
			resize(){
				this.myChart.resize()
			},
			initChart() {
				let option = {
					xAxis: {
						data:this.xdata() ,
					},
          legend:{
						data:['点击人数',this.tooltip]
					},
					yAxis: {
						minInterval: 1,
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						}
					},
					grid: {
						left: '1%',
						top: '15%',
						right: '3%',
						bottom: '4%',
						containLabel: true
					},
					series: [{
            name:'点击人数',
						data:this.datainfo[0].map((v)=>{
							return v.value
						}),
						type: "line",
						symbol: 'circle',
						symbolSize: 10, //0
						itemStyle: {
							color: "#E6A23C",
							background: "#E6A23C",
						},
						lineStyle: {
							color: "#E6A23C",
						},
					},{
            name:this.tooltip,
						data: this.datainfo[1].map((v)=>{
							return v.value
						}),
						type: "line",
						symbol: 'circle',
						symbolSize: 10, //折线点的大小
						itemStyle: {
							color: "#409EFF",
							background: "#409EFF",
						},
						lineStyle: {
							color: "#409EFF",
						},
					}, ],
					tooltip: {
            trigger: "axis",
          },
				};
				this.$nextTick(() => {
					this.myChart.setOption(option);
				});
			},
			xdata(){
				let data =  this.datainfo[0].map((v) => {
							return v.label;
					})
					return data
			},

      
		},
	};
</script>

<style></style>
